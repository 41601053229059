body {
  background-color: #fdf5f5;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-size: contain;
  max-width: 1368px;
  margin: 0 auto;
}

.HeaderWrapper {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/header-bg.jpg") no-repeat right top;
  background-size: contain;
}

.logoWrapper {
  width: max-content;
}

.bannerWrapper {}

main {

}

.navbar-expand-lg .navbar-nav {
  gap: 50px;
}

.navbar-nav a {
  color: #011E3D;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navbar-nav a.special-menu-item {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/button-bg.png") no-repeat center center;
  background-size: cover;
  padding-left: 23px !important;
  padding-right: 30px !important;
  padding-top: 10px;
  height: 50px;
  color: #ffffff !important;
}

.submitButton {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/button-bg.png") no-repeat center center;
  background-size: cover;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 47px;
  width: 180px;
  color: #FCFDFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.submitButton:hover {
  border: none;
  background-color: transparent;
}

.boxesWrapper {
  background: #011D38;
  color: #F6FBFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}

.groupBox {
  margin-top: -30px;
}

.imageTitle {
  color: #F6FBFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  max-width: 200px;
  text-align: center;
  margin: auto;
}

.imageSubContent {
  max-width: 250px;
}

.groupBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.imageTitle, .imageSubContent {
  flex: 0 0 auto; /* Do not grow or shrink */
}

img {
  width: 100%; /* Make images responsive */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Optional, for better image handling */
}

.FooterWrapper {
  background: #011D38;
  text-align: left;
  color: #F6FBFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textPLeft {
  max-width: 383px;
  border-top: solid 1px #00746A;
}

.textPRight {
  max-width: 383px;
  text-align: right;
}

.modal-header {
  border-bottom: none;
}

.modal-content {
  padding-bottom: 50px;
  border-radius: 30px;
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/modal-bg.png"), lightgray -498.715px -119.065px / 236.565% 133.789% no-repeat;
  background-repeat: no-repeat;
  background-position-x: 350px;
  background-position-y: 260px;
  background-color: #ffffff;
}

.modal-title {
  color: #00746A;
  text-align: left;
  font-family: Montserrat;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modalSubTitle {
  color: #011E3D;
  text-align: left;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-backdrop {
  background-color: #011D38;
  --bs-backdrop-opacity: 0.9;
}

.btn-close {
  position: absolute;
  top: 14px;
  right: 18px;
}

input.form-control {
  border-radius: 50px;
  border: solid 2px rgba(1, 30, 61, 0.65);
  color: rgba(1, 30, 61, 0.65);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  text-indent: 50px;
}

.fullName {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/full_name.png") no-repeat left center;
  background-size: 40px;
  background-position-x: 10px;
}

.phoneNumber {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/phone.png") no-repeat left center;
  background-size: 40px;
  background-position-x: 10px;
}

.email {
  background: url("https://roadwaymovingservicesnyc.com/widgets/images/laser-it/email.png") no-repeat left center;
  background-size: 40px;
  background-position-x: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Changed to semi-transparent black */
  z-index: 9999;
  color: #FFFFFF;
  font-size: 12px;
}

.loader {
  border: 12px solid #f3f3f3;
  border-top: 12px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-message {
  margin-top: 20px; /* You can adjust this value as per your design needs */
  font-size: 1.5em;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .logoWrapper img {
    width: 100px;
  }

  .HeaderWrapper {
    align-items: start !important;
  }

  .navigationWrapper {
    float: right;
    justify-content: end !important;
    margin-right: 10px;
    margin-top: 20px;
  }

  .navigationWrapper nav {
    justify-content: end !important;
    margin-top: 10px;
  }

  .navbar {
    background-color: #ffffff;
    padding: 0;
  }

  #basic-navbar-nav {
    position: absolute;
    top: 0;
    right: 50px;
    background: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    border: solid 1px #ccc;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.07);
  }

  .navbar-expand-lg .navbar-nav {
    gap: unset;
  }

  .navbar-nav a.special-menu-item {
    min-width: 170px;
  }

  .boxesWrapper {
    display: block !important;
  }

  .groupBox {
    margin-top: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .imageSubContent {
    max-width: unset;
  }
}
